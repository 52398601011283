.HeaderRoot {
  .Nav {
    padding-left: 12px;
    padding-right: 12px;
    position: fixed;
    top: 0px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 9999;
    .MenuContainer {
      position: relative;
      margin-right: 12px;
      .Menu,
      .MenuOpen {
        position: absolute;
      }
      .MenuOpen {
        opacity: 1;
        transition: 0.3s;
        &.Closed {
          opacity: 0;
          rotate: -135deg;
        }
      }
      .Menu {
        transition: 0.3s;
        &.Open {
          rotate: 135deg;
          opacity: 0;
        }
      }
    }
    svg {
      width: 38px;
    }
    p {
      font-size: 18px;
      margin-left: 8px;
    }
    &.Open {
      background-color: #fff;
    }
    @include min(600px) {
      display: none;
    }
    &.Desktop {
      display: none;
      width: 100%;
      @include min(600px) {
        display: flex;
      }
      > div {
        width: 100%;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        #Logo {
          display: none;
          @include min(1200px) {
            display: unset;
          }
          position: absolute;
          top: 20px;
          left: 16px;
        }
        .Container {
          max-width: 1100px;
          margin-left: auto;
          margin-right: auto;
          padding: 16px 0px;
          a {
            transition: color 0.3s;
            &:hover {
              color: #339cff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
