.SidebarRoot {
  span {
    cursor: pointer;
  }
  .MuiDrawer-paper {
    margin-top: 80px;
    background-color: #fff;
  }
  @include min(600px) {
    display: none;
  }
}
