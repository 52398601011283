@import "./Mixins";
@import "./components/Header";
@import "./components/Home";
@import "./components/Projects";
@import "./components/About";
@import "./components/Sidebar";

body {
  overflow-x: hidden;
  article {
    overflow-x: hidden;
  }
}