.HeaderRoot .Nav {
  padding-left: 12px;
  padding-right: 12px;
  position: fixed;
  top: 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0) 100%);
  z-index: 9999;
}
.HeaderRoot .Nav .MenuContainer {
  position: relative;
  margin-right: 12px;
}
.HeaderRoot .Nav .MenuContainer .Menu,
.HeaderRoot .Nav .MenuContainer .MenuOpen {
  position: absolute;
}
.HeaderRoot .Nav .MenuContainer .MenuOpen {
  opacity: 1;
  transition: 0.3s;
}
.HeaderRoot .Nav .MenuContainer .MenuOpen.Closed {
  opacity: 0;
  rotate: -135deg;
}
.HeaderRoot .Nav .MenuContainer .Menu {
  transition: 0.3s;
}
.HeaderRoot .Nav .MenuContainer .Menu.Open {
  rotate: 135deg;
  opacity: 0;
}
.HeaderRoot .Nav svg {
  width: 38px;
}
.HeaderRoot .Nav p {
  font-size: 18px;
  margin-left: 8px;
}
.HeaderRoot .Nav.Open {
  background-color: #fff;
}
@media screen and (min-width: 600px) {
  .HeaderRoot .Nav {
    display: none;
  }
}
.HeaderRoot .Nav.Desktop {
  display: none;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .HeaderRoot .Nav.Desktop {
    display: flex;
  }
}
.HeaderRoot .Nav.Desktop > div {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.HeaderRoot .Nav.Desktop > div #Logo {
  display: none;
  position: absolute;
  top: 20px;
  left: 16px;
}
@media screen and (min-width: 1200px) {
  .HeaderRoot .Nav.Desktop > div #Logo {
    display: unset;
  }
}
.HeaderRoot .Nav.Desktop > div .Container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0px;
}
.HeaderRoot .Nav.Desktop > div .Container a {
  transition: color 0.3s;
}
.HeaderRoot .Nav.Desktop > div .Container a:hover {
  color: #339cff;
  cursor: pointer;
}

.HomeRoot {
  min-height: 100vh;
  position: relative;
}
.HomeRoot:hover + #NavHome {
  color: #339cff;
}
@media screen and (min-width: 600px) {
  .HomeRoot {
    max-width: unset;
  }
}
@media screen and (min-width: 600px) {
  .HomeRoot .Small-Portrait {
    display: none;
  }
}
.HomeRoot .Big-Portrait {
  display: none;
}
@media screen and (min-width: 600px) {
  .HomeRoot .Big-Portrait {
    display: initial;
  }
}
.HomeRoot .Intro {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
  max-width: 335px;
}
@media screen and (min-width: 600px) {
  .HomeRoot .Intro {
    max-width: 520px;
    align-items: center;
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 990px) {
  .HomeRoot .Intro {
    max-width: 858px;
    align-items: unset;
  }
}
.HomeRoot .Intro .Portrait {
  width: 200px;
}
@media screen and (min-width: 990px) {
  .HomeRoot .Intro .Portrait {
    width: unset;
  }
}
.HomeRoot #colorFix {
  height: 64px;
}
.HomeRoot .Stack img {
  height: 42px;
}
@media screen and (min-width: 990px) {
  .HomeRoot .Stack img {
    height: 64px;
  }
}
.HomeRoot .Stack div {
  padding-right: 14px;
}
.HomeRoot .Stack :last-child {
  padding-right: 0px;
}
.HomeRoot .Socials {
  display: none;
}
@media screen and (min-width: 600px) {
  .HomeRoot .Socials {
    display: unset;
    max-width: 42px;
  }
  .HomeRoot .Socials svg {
    height: 42px;
  }
  .HomeRoot .Socials img {
    height: 42px;
  }
  .HomeRoot .Socials :nth-child(2) {
    min-width: 80px;
  }
}
@media screen and (min-width: 990px) {
  .HomeRoot .Socials {
    max-width: 64px;
  }
  .HomeRoot .Socials svg {
    height: 64px;
  }
  .HomeRoot .Socials img {
    height: 64px;
  }
}
.HomeRoot .Wave {
  position: absolute;
  left: 0px;
  width: 100vw;
  z-index: -1;
  bottom: 0px;
}

.CertificationRoot {
  position: relative;
}
.CertificationRoot .Wave {
  position: absolute;
  left: 0px;
  width: 100vw;
  z-index: -1;
  bottom: 0px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}
.ProjectsRoot {
  background-color: #e8f4ff;
  padding: 40px 20px;
  text-align: center;
  min-height: 100vh;
  max-width: 100%;
}
.ProjectsRoot .Title {
  margin-bottom: 24px;
}
@media screen and (min-width: 990px) {
  .ProjectsRoot .Title {
    margin-bottom: 120px;
  }
}
.ProjectsRoot .GridCard {
  position: relative;
  margin-bottom: 24px;
}
.ProjectsRoot .GridCard h2 {
  text-align: left;
}
.ProjectsRoot .GridCard:hover .BottomCard {
  opacity: 1;
}
.ProjectsRoot .GridCard .BottomCard {
  opacity: 0;
  transition: opacity 0.3s;
}
.ProjectsRoot .GridCard .BottomCard a {
  min-width: 101.8px;
}
.ProjectsRoot .GridImage {
  margin-bottom: 12px;
  max-width: 450px;
  flex-grow: 2;
}
.ProjectsRoot .GridImage img {
  width: 100%;
}
.ProjectsRoot .BottomCard {
  justify-content: end;
}
@media screen and (min-width: 990px) {
  .ProjectsRoot .GridCard .Left {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }
  .ProjectsRoot .GridCard .Left h2 {
    text-align: right;
  }
  .ProjectsRoot .GridCard .Left p {
    text-align: right;
  }
  .ProjectsRoot .GridCard .Left .GridImage {
    width: 300px;
    flex-shrink: 0;
    margin-left: 24px;
  }
  .ProjectsRoot .GridCard .Left .GridImage img {
    width: 100%;
  }
  .ProjectsRoot .GridCard .Left .BottomCard {
    justify-content: start;
  }
  .ProjectsRoot .GridCard .Left .BottomCard div {
    width: revert;
  }
}
@media screen and (min-width: 990px) {
  .ProjectsRoot .GridCard .Right {
    flex-wrap: nowrap;
  }
  .ProjectsRoot .GridCard .Right h2 {
    text-align: left;
  }
  .ProjectsRoot .GridCard .Right p {
    text-align: left;
  }
  .ProjectsRoot .GridCard .Right .GridImage {
    width: 300px;
    flex-shrink: 0;
    margin-right: 24px;
  }
  .ProjectsRoot .GridCard .Right .GridImage img {
    width: 100%;
  }
  .ProjectsRoot .GridCard .Right .BottomCard {
    justify-content: end;
  }
  .ProjectsRoot .GridCard .Right .BottomCard div {
    width: unset;
  }
}

.CertificationRoot {
  padding: 40px 20px;
  text-align: center;
  min-height: 100vh;
  max-width: 100%;
}
.CertificationRoot .Title {
  margin-bottom: 24px;
}
@media screen and (min-width: 990px) {
  .CertificationRoot .Title {
    margin-bottom: 120px;
  }
}
.CertificationRoot .Title h1 {
  position: relative;
}
.CertificationRoot .Title img {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
}
.CertificationRoot div .GridImage {
  max-width: 100%;
  margin-bottom: 32px;
  height: 250px;
}
.CertificationRoot .GridCard {
  position: relative;
  margin-bottom: 24px;
}
.CertificationRoot .GridCard h2 {
  text-align: left;
}
.CertificationRoot .GridCard:hover .BottomCard {
  opacity: 1;
}
.CertificationRoot .GridCard .BottomCard {
  opacity: 0;
  transition: opacity 0.3s;
}
.CertificationRoot .GridCard .BottomCard a {
  min-width: 101.8px;
}

.AboutRoot {
  position: relative;
  min-height: 100vh;
}
.AboutRoot .AboutContainer {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 120px;
}
@media screen and (min-width: 990px) {
  .AboutRoot .AboutContainer {
    flex-wrap: nowrap;
  }
  .AboutRoot .AboutContainer img {
    margin-right: 12px;
  }
}
.AboutRoot .Wave {
  position: absolute;
  left: 0px;
  width: 100vw;
  z-index: -1;
  transform: rotate(180deg);
  top: 0px;
}

.SidebarRoot span {
  cursor: pointer;
}
.SidebarRoot .MuiDrawer-paper {
  margin-top: 80px;
  background-color: #fff;
}
@media screen and (min-width: 600px) {
  .SidebarRoot {
    display: none;
  }
}

body {
  overflow-x: hidden;
}
body article {
  overflow-x: hidden;
}/*# sourceMappingURL=index.css.map */