.AboutRoot {
  position: relative;
  min-height: 100vh;
  .AboutContainer{
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 120px;
    @include min(990px){
      flex-wrap: nowrap;
      img{
        margin-right: 12px;
      }
    }
  }
  .Wave {
    @include Wave(true);
  }
}
