@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}

.ProjectsRoot {
  background-color: #e8f4ff;
  .Title {
    margin-bottom: 24px;
    @include min(990px) {
      margin-bottom: 120px;
    }
  }
  @include GridView();
  .GridImage {
    margin-bottom: 12px;
    max-width: 450px;
    flex-grow: 2;
    img{
      width: 100%;
    }
  }
  .BottomCard {
    justify-content: end;
  }
  .GridCard {
    .Left {
      @include min(990px) {
        h2 {
          text-align: right;
        }
        p {
          text-align: right;
        }
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        .GridImage{
          width: 300px;
          flex-shrink: 0;
          margin-left: 24px;
          img{
            width: 100%;
          }
        }
        .BottomCard {
          justify-content: start;
          div {
            width: revert;
          }
        }
      }
    }
    .Right {
      @include min(990px) {
        h2 {
          text-align: left;
        }
        p {
          text-align: left;
        }
        flex-wrap: nowrap;
        .GridImage{
          width: 300px;
          flex-shrink: 0;
          margin-right: 24px;
          img{
            width: 100%;
          }
        }
        .BottomCard {
          justify-content: end;
          div {
            width: unset;
          }
        }
      }
    }
  }
}

.CertificationRoot {
  .Title {
    margin-bottom: 24px;
    @include min(990px) {
      margin-bottom: 120px;
    }
    h1 {
      position: relative;
    }
    img {
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
    }
  }
  div {
    .GridImage {
      max-width: 100%;
      margin-bottom: 32px;
      height: 250px;
    }
  }
  @include GridView();
}
