.HomeRoot {
  min-height: 100vh;
  position: relative;
  &:hover {
    +#NavHome {
      color: #339cff;
    }
  }
  @include min(600px){
    max-width: unset;
  }
  .Small-Portrait {
    @include min(600px) {
      display: none;
    }
  }
  .Big-Portrait {
    display: none;
    @include min(600px) {
      display: initial;
    }
  }
  .Intro {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 12px;
    max-width: 335px;
    @include min(600px){
      max-width: 520px;
      align-items: center;
      flex-wrap: nowrap;
    }
    @include min(990px){
      max-width: 858px;
      align-items: unset;
    }
    .Portrait {
      width: 200px;
      @include min(990px) {
        width: unset;
      }
    }
  }
  #colorFix {
    height: 64px;
  }
  .Stack {
    img {
      height: 42px;
      @include min(990px) {
        height: 64px;
    }
    }
    div{
      padding-right: 14px;
    }
    :last-child{
      padding-right: 0px;
    }
  }
  .Socials {
    display: none;
    @include min(600px) {
      display: unset;
      max-width: 42px;
      svg{
        height: 42px;
      }
      img{
        height: 42px;
      }
      :nth-child(2){
        min-width: 80px;
      }
    }
    @include min(990px){
      max-width: 64px;
      svg{
        height: 64px;
      }
      img{
        height: 64px;
      }
    }
  }
  .Wave {
    @include Wave();
  }
}
.CertificationRoot {
  position: relative;
  .Wave {
    @include Wave();
  }
}
